export default {
  name: 'viewMixin',
  data() {
    return {
    }
  },
  created() {
    this.addView().then()
  },
  methods: {
    async addView() {
      const route = this.$route

      let cateCode = ''
      if (route.name === 'tip/detail') cateCode = 10
      if (route.name === 'lab/detail') cateCode = 20
      if (route.name === 'ggomunity/detail') cateCode = 80

      if (!this.$cookie.get(route.name+route.params.idx)) {
        // const expires = '1m'
        // const domain = location.host.split(':')[0]
        this.$cookie.set(route.name+route.params.idx, route.name+route.params.idx, { expires: '1m' })

        const params = {
          cateCode: cateCode,
          boIdx: route.params.idx
        }
        await this.$api.addBoardView(params);
      }
    }
  }
}
