<template>
  <div class="inner-layout-800">

    <div class="detailP-subject-box">
      <div class="category">{{ item.categoryName }} / {{ item.petName }}</div>
      <div class="heading-1">{{ item.title }}</div>
    </div>

    <div class="detailP-profile-lable-box">
      <profile :profileType="'detail'" :item="item"/>

      <icon-label :item="item"/>
    </div>

    <div class="detailP-thumb">
      <image-alt :src="item.src" altType="detail"/>
    </div>

    <div v-html="item.content" class="detailP-content"/>

    <quick-box :cateCode="10" :item="item" />

    <recommend/>

    <div class="horizon-bar mb-60"></div>

    <comment ref="comments" :cateCode="10" :count="item.comment"/>

  </div>
</template>

<script>
import {mapActions} from "vuex"
import viewMixin from "@/mixins/viewMixin"
export default {
  name: "tip",
  mixins: [viewMixin],
  data: function () {
    return {
      idx: this.$route.params.idx,
      item: {},
      isOpenLoginPopup: false,
      isOpenPopup: false,
      popupMsg: null,
    }
  },
  created() {
    this.setItem();
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      if (!this.idx) await this.$router.push('/tip');
      const { result, data } = await this.$api.getBoardDetail({ cateCode: 10, boIdx: this.idx })
      switch (result) {
        case 'success':
          this.item = data
          break;
        case 'empty':
          await this.$router.push('/tip')
          break;
        case 'deleted':
          this.setMsgPopup({
            type: 'alert',
            message: this.$msg('ok.deleted'),
            okay: '',
            okayCallback: () => {
              this.setMsgPopup()
              this.$router.go(-1)
            },
          })
          break;
        default:
      }
    },
  }
}
</script>
